@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .debug-red {
    border: 2px solid red;
  }
  .debug-blue {
    border: 2px solid blue;
  }
  .debug-green {
    border: 2px solid green;
  }
  .debug-yellow {
    border: 2px solid yellow;
  }
  .debug-orange {
    border: 2px solid orange;
  }
}
